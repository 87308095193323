import { Variants } from 'framer-motion';
import { easeOutQuart } from '@/utils/easings';

export const clipVariants: Variants = {
    hidden: {
        y: '-105%',
        transition: {
            duration: 0.65,
            ease: easeOutQuart,
        },
    },
    visible: {
        y: '0%',
        transition: {
            duration: 0.65,
            ease: easeOutQuart,
            delay: 0.1,
        },
    },
};
