import classNames from 'classnames';
import { m, useInView } from 'framer-motion';
import { HTMLAttributes, useRef } from 'react';
import { clipVariants } from '@/variants/clip';

interface Props extends HTMLAttributes<HTMLElement> {}

const ButtonClipContainer = ({ children, ...props }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const inView = useInView(containerRef, { once: true });

    return (
        <div
            {...props}
            ref={containerRef}
            className={classNames('clip-container button-clip-container', props.className)}
        >
            <m.div variants={clipVariants} initial="hidden" animate={inView ? 'visible' : 'hidden'}>
                {children}
            </m.div>
        </div>
    );
};

export default ButtonClipContainer;
