import { useEffect, useRef, useState } from 'react';
import { Socnet } from '@/types';
import ChipsaSVG from '@/svg/chipsa.svg';
import { formatPhoneHref } from '@/utils/strings';
import animateScrollTo from 'animated-scroll-to';
import Button from '@/components/shared/Button';
import SplitText from '@/components/shared/SplitText';
import ButtonClipContainer from '@/components/shared/ButtonClipContainer';
import Link from '@/components/shared/Link';
import { useTypograf } from '@/hooks/use-typograf';

type Props = {
    socnets: Socnet[];
    footerText: string;
    footerPhone: string;
    footerEmail: string;
};

const Footer = ({ socnets, footerText, footerPhone, footerEmail }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [theme, setTheme] = useState('black');
    const tpFooterText = useTypograf(footerText);

    useEffect(() => {
        const onNewPageReady = () => {
            setTheme(
                document.documentElement.classList.contains('main-page') ||
                    document.documentElement.classList.contains('about-page')
                    ? 'gray-brown'
                    : 'black',
            );
        };

        onNewPageReady();
        document.addEventListener('new-page-ready', onNewPageReady);

        return () => {
            document.removeEventListener('new-page-ready', onNewPageReady);
        };
    }, []);

    return (
        <footer
            ref={containerRef}
            className="footer wrapper js-app-nav-theme-trigger"
            data-theme={theme}
            itemScope
            itemType="http://schema.org/WPFooter"
        >
            <SplitText className="footer-top text-l">{tpFooterText}</SplitText>

            <div className="footer-body">
                <div className="footer-center">
                    <div className="footer-left">
                        <ul className="list-unstyled footer-socnets">
                            {socnets
                                .sort((a, b) => b.name.length - a.name.length)
                                .map((item, i) => {
                                    return (
                                        <li key={i}>
                                            <ButtonClipContainer>
                                                <Button
                                                    geometryVariant="large"
                                                    tag="a"
                                                    href={item.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {item.name}
                                                </Button>
                                            </ButtonClipContainer>
                                        </li>
                                    );
                                })}
                        </ul>

                        <SplitText className="rotor-copyright m-text-xs">
                            {`rotormine © ${new Date().getFullYear()}`}
                        </SplitText>
                        <meta itemProp="maintainer" content="rotormine" />
                        <meta itemProp="copyrightYear" content={`${new Date().getFullYear()}`} />
                        <meta itemProp="creator" content="chipsa" />
                    </div>

                    <div className="footer-right">
                        <div className="footer-contacts" itemScope itemType="https://schema.org/Organization">
                            <meta itemProp="name" content="rotor-watch" />
                            <ButtonClipContainer className="footer-phone">
                                <Button
                                    geometryVariant="large"
                                    tag="a"
                                    href={`tel:${formatPhoneHref(footerPhone)}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    itemProp="telephone"
                                >
                                    {footerPhone}
                                </Button>
                            </ButtonClipContainer>

                            <ButtonClipContainer className="clip-container button-clip-container footer-email">
                                <Button
                                    geometryVariant="large"
                                    tag="a"
                                    href={`mailto:${footerEmail}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    itemProp="email"
                                >
                                    {footerEmail}
                                </Button>
                            </ButtonClipContainer>
                        </div>
                        <Button
                            className="copyright chipsa-copyright"
                            tag="a"
                            target="_blank"
                            rel="noreferrer"
                            href="https://chipsa.ru/?utm_source=rotor-watch&utm_medium=works&utm_campaign=all"
                        >
                            <div
                                className="copyright__text m-text-xs"
                                dangerouslySetInnerHTML={{ __html: 'made in&nbsp;chipsa' }}
                            />
                            <div className="copyright__icon">
                                <ChipsaSVG />
                            </div>
                        </Button>
                    </div>

                    <Button
                        geometryVariant="mustache"
                        onClick={() => {
                            animateScrollTo(0);
                        }}
                        className="footer-up-btn"
                    >
                        Наверх
                    </Button>

                    <Link href="/oferta" className="oferta-link link link-underlined m-text-xs">
                        Пользовательское соглашение
                    </Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
